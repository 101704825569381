import loadable from '@loadable/component';
import { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'app/styles/index.css';
import AppLayout from 'app/AppLayout';
import ApiProvider from 'core/api/ApiProvider';
import AuthProvider from 'core/auth/AuthProvider';

import Dashboard from './app/pages/Dashboard';
import Version from './core/containers/Version';

const SupplierList = loadable(() => import('supplier/pages/SupplierList'));
const SupplierDetail = loadable(() => import('supplier/pages/SupplierDetail'));
const Profile = loadable(() => import('app/pages/Profile'));
const Login = loadable(() => import('app/pages/Login'));
const ForgotPassword = loadable(() => import('app/pages/ForgotPassword'));
const ResetPassword = loadable(() => import('app/pages/ResetPassword'));

const App: FC = () => {
  return (
    <Version>
      <AuthProvider>
        <ApiProvider>
          <Router>
            <Routes>
              {/* Unauthorized routes */}
              <Route element={<Login />} path="/login" />
              {/* TODO: /sign-up ? */}
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route element={<ResetPassword />} path="/reset-password/:token" />

              {/* Authorized routes */}
              <Route element={<AppLayout />} path="/">
                <Route element={<Dashboard />} path="/" />
                <Route element={<SupplierList />} path="/suppliers" />
                <Route element={<SupplierDetail />} path="/suppliers/:supplierId" />
                <Route element={<Profile />} path="/profile" />
              </Route>
            </Routes>
          </Router>
        </ApiProvider>
      </AuthProvider>
    </Version>
  );
};

export default App;
