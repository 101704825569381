import { createContext, Dispatch, SetStateAction } from 'react';

import { LoginResponse } from './hooks/useLogin';
import User from './User';

export interface AuthContextState {
  user: User;
  token: LoginResponse;
}

const AuthContext = createContext<AuthContextState | undefined | null>(undefined);

const SetAuthContext = createContext<Dispatch<SetStateAction<AuthContextState | undefined | null>>>(() => {
  //
});

export { SetAuthContext };

export default AuthContext;
