import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsWithChildren, useMemo } from 'react';

import t from 'core/helpers/t';

import useAuthToken from '../auth/hooks/useAuthToken';

const showDevPanel = import.meta.env.VITE_APP_ENABLE_QUERY_DEV;

function ApiProvider({ children }: PropsWithChildren) {
  const token = useAuthToken();

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          queryFn: async ({ queryKey, signal }): Promise<unknown> => {
            const key = queryKey.join('/').replace('/?', '?');

            const headers = new Headers();
            if (token) {
              headers.append('Authorization', `Bearer ${token.access_token}`);
            }

            const url = `${import.meta.env.VITE_APP_API_PATH}/${key}`;

            const response = await fetch(url, { headers, signal });

            // TODO: add toast ??

            return response.json();
          },
        },
        mutations: {
          async mutationFn(variables) {
            const key = this.mutationKey;

            if (!key) {
              throw new Error('Provide mutation key');
            }

            const method = isReqMethod(key[0]) ? key[0] : undefined;
            const url = key.join('/').replace(`${method}/`, '');

            const headers = new Headers();
            if (token) {
              headers.append('Authorization', `Bearer ${token.access_token}`);
            }
            if (variables instanceof FormData) {
              // headers.append('Content-Type',  undefined);
            } else {
              headers.append('Content-Type', 'application/json');
            }
            headers.append('Accept', 'application/json');

            let body: null | undefined | string | FormData;
            let id: number | undefined = undefined;

            if (isNumber(variables)) {
              id = variables;
            } else if (variables instanceof FormData) {
              body = variables;
            } else if (variables && typeof variables === 'object') {
              body = JSON.stringify(variables);
            }

            let link = `${import.meta.env.VITE_APP_API_PATH}/${url}`;
            if (id) {
              link += `/${id}`;
            }

            const response = await fetch(link, { body, method: method ?? 'POST', headers });

            let data: unknown;

            try {
              data = await response.json();
            } catch {
              // cannot parse err msg
            }

            if (!response.ok) {
              // @ts-ignore TODO: ...
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              throw new Error(t('Invalid mutation (More: "{more}"', { more: data?.message ?? t('Unknown') }));
            }

            // invalidate all queries on the way
            // [suppliers]
            // [suppliers, 1]
            // [suppliers, 1, contact-persons]
            // [suppliers, 1, contact-persons, 2]
            //  await queryClient.invalidateQueries({ queryKey: [...queryKey, id.toString()] });

            await queryClient.invalidateQueries({
              predicate: (q) => {
                const k = q.queryKey.join('/');

                return url.split('/').some((_, i, a) => k.includes(a.slice(0, i + 1).join('/')));
              },
            });

            return data;
          },
        },
      },
    });
  }, [token]);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {showDevPanel === 'true' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default ApiProvider;

type Method = 'POST' | 'PATCH' | 'PUT' | 'DELETE';

function isReqMethod(key: unknown): key is Method {
  const k = typeof key === 'string' ? key : '';
  return ['POST', 'PATCH', 'PUT', 'DELETE'].includes(k);
}

function isNumber(val: unknown): val is number {
  return typeof val === 'number';
}
