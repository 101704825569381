import { useCallback } from 'react';

import useSetAuthState from './useSetAuthState';

export interface LoginResponse {
  access_token: string;
  token_type: 'bearer';
  expires_in: number;
}

export default function useLogout() {
  const setAuthState = useSetAuthState();

  return useCallback(
    (cb?: (done: boolean) => void) => {
      const requestOptions = {
        method: 'POST',
      } as const;

      fetch(`${import.meta.env.VITE_APP_API_PATH}/auth/logout`, requestOptions)
        .then((r) => {
          cb?.(!!r.ok);
        })
        .catch(() => {
          cb?.(false);
        })
        .finally(() => {
          setAuthState(undefined);
        });
    },
    [setAuthState]
  );
}
