import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useUser from 'core/auth/hooks/useUser';
import Sidebar from 'core/components/sidebar/Sidebar';
import TopBar from 'core/components/TopBar';

import 'app/extendYup';

const AppLayout: FC = () => {
  const user = useUser();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Sidebar />

      <main className="ml-20">
        <TopBar />
        <div className="pt-24">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default AppLayout;
