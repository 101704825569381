import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { LiaUserCircle } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

import useLogout from 'core/auth/hooks/useLogout';
import t from 'core/helpers/t';

import styles from './TopBar.module.scss';

export default function TopBar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleLogout = useLogout();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={styles.topBar}>
      <div className={styles.leftSection}>
        <div className={'ml-2'}>
          <div className={styles.title}>
            <h1>{t('Zoznam dodávateľov')}</h1>
          </div>
          <div className="text-gray-400">
            <small>{t('Ocean / Zoznam dodávateľov')}</small>
          </div>
        </div>
      </div>
      <div ref={dropdownRef} className={styles.dropdownContainer}>
        <LiaUserCircle
          className="size-10 cursor-pointer text-blue-500"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        />
        {dropdownOpen && (
          <div className={styles.dropdownMenu}>
            <button
              disabled
              className={clsx(styles.dropdownItem, 'disabled:cursor-not-allowed disabled:text-gray-500')}
              onClick={() => {
                navigate('/profile');
                setDropdownOpen(false);
              }}
            >
              {t('Profil')}
            </button>

            <button
              className={styles.dropdownItem}
              onClick={() => {
                handleLogout();
              }}
            >
              {t('Odhlásiť sa')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
